<template>
  <div
    v-for="banner in banners"
    :key="banner.id"
    class="banner"
    :class="[
      banner.type,
      banner.style === 'full-width' ? 'w-full' : 'w-full rounded-[8px]',
    ]"
  >
      <div
        class="flex flex-row gap-4 m-4 align-center cursor-pointer"
        :class="
          banner.style === 'full-width' ? 'justify-center' : 'justify-start'
        "
        @click="executeAction(banner.content.cta)"
      >
        <div :class="['icon-box', props.location === 'top' ? banner.type : '']">
          <i :class="getIconClass(banner.type)"></i>
        </div>

        <p class="text-content content-center">
          <strong class="font-semibold">{{ $t(banner.content.msg) }}</strong>
          <svg
            viewBox="0 0 2 2"
            class="mx-2 inline size-1 fill-current"
            aria-hidden="true"
            v-if="banner.content.cta.text && banner.content.cta.text !== ''"
          >
            <circle cx="1" cy="1" r="1" />
          </svg>
          <span>{{ $t(banner.content.cta.text) }}</span>
          <i class="mdi mdi-arrow-right"></i>
        </p>
      </div>
      <div v-if="banner.dismissible" class="button-container m-4">
        <button @click="dismiss(banner)" class="closebutton" :alt="$t('Close')" aria-label="Close">
          <i class="mdi mdi-close"></i>
        </button>
      </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const modalConfig = useModals()
const router = useRouter()
const { userProfile } = useKeycloak()
const subscriptionApi = useSubscriptions()
const runtimeConfig = useRuntimeConfig()
const banners = ref([])
const usedFreeTrial = ref(false)
const props = defineProps({
  location: {
    type: String,
    default: 'top',
    validator: value => ['top', 'home-screen'].includes(value),
  },
})

onMounted(async () => {
  checkSubscriptionStatus()
  parseCookie()
  await loadBanners()
})

const dismissedBanners = ref([])
const bannerCookie = useCookie('transkribus-banner', {
  maxAge: 60 * 60 * 24 * 7,
}) // Cookie expires in 7 days

const parseCookie = () => {
  try {
    dismissedBanners.value = bannerCookie.value ? bannerCookie.value : []
    dismissedBanners.value.forEach(banner => {
      banner.date = new Date(banner.date).toISOString().split('T')[0]
    })
  } catch (error) {
    console.error('Error parsing cookie:', error)
    dismissedBanners.value = []
  }
}

const dismiss = banner => {
  dismissedBanners.value.push({
    id: banner.id,
    date: new Date().toISOString().split('T')[0],
  })
  bannerCookie.value = JSON.stringify(dismissedBanners.value)
  banners.value = banners.value.filter(b => b.id !== banner.id)
}

const isBannerDismissed = banner => {
  const dismissedBanner = dismissedBanners.value.find(
    dismissedBanner => dismissedBanner.id === banner.id
  )
  if (dismissedBanner) {
    if (!banner.startdate) {
      return true
    }
    const bannerStartDate = new Date(banner.startdate)
      .toISOString()
      .split('T')[0]
    return bannerStartDate <= dismissedBanner.date
  }
  return false
}

const isActive = banner => {
  let env = runtimeConfig.public.ENVIRONMENT_NAME
  if (!banner.conditions.instances?.includes(env)) {
    return false
  }

  if (banner.location !== props.location) {
    return false
  }

  const currentDate = new Date().toISOString()
  if (banner.startdate) {
    const bannerStartDate = new Date(banner.startdate).toISOString()
    if (bannerStartDate > currentDate) {
      return false
    }
  }
  if (banner.enddate) {
    const bannerEndDate = new Date(banner.enddate).toISOString()
    if (bannerEndDate < currentDate) {
      return false
    }
  }

  const userRole = getUserRole()
  const allowedRoles = banner.conditions.role
  if (!allowedRoles.includes(userRole) && !allowedRoles.includes('All')) {
    return false
  }

  return true
}

const getUserRole = () => {
  if (userProfile.Team) {
    return 'Team'
  } else if (userProfile.Individual) {
    return 'Individual'
  } else if (userProfile.Scholar) {
    return 'Scholar'
  } else if (userProfile.Era) {
    return 'Era'
  } else if (userProfile.Epoch) {
    return 'Epoch'
  } else if (userProfile.Eon) {
    return 'Eon'
  }
  return ''
}

const loadBanners = async () => {
  try {
    const files = import.meta.glob('~/assets/banners/*.json')
    const bannerPromises = Object.keys(files).map(async path => {
      const banner = await files[path]()
      return banner.default
    })
    banners.value = await Promise.all(bannerPromises)
    banners.value = banners.value.filter(
      banner =>
        banner.location === props.location &&
        isActive(banner) &&
        !isBannerDismissed(banner)
    )
  } catch (error) {
    console.error('Error loading banners:', error)
  }
}

const getIconClass = type => {
  switch (type) {
    case 'info':
      return 'mdi mdi-bullhorn'
    case 'warning':
      return 'mdi mdi-alert'
    case 'promo':
      return 'mdi mdi-play-box-multiple'
    case 'upgrade':
      return 'mdi mdi-arrow-up-circle'
    default:
      return ''
  }
}

function executeAction(cta) {
  if (cta.url) {
    navigateToUrl(cta.url)
  } else if (cta.modal) {
    openModal(cta.modal)
  }
}

const navigateToUrl = url => {
  if (url.startsWith('/')) {
    router.push(url)
  } else {
    window.open(url, '_blank')
  }
}

const checkSubscriptionStatus = async () => {
  const { subscription } =
    await subscriptionApi.getSubscription({ userId: userProfile.UserId })
  
  if (subscription?.usedTrial) {
    usedFreeTrial.value = true
  }
}

async function openModal(trigger) {
  const modals = await modalConfig.getModals(usedFreeTrial.value)
  const modal = modals.find(modal => modal.trigger === trigger)
  modal.open()
}

onMounted(async () => {
  parseCookie()
  await loadBanners()
})
</script>

<style scoped>
.banner {
  --info-text-color: #fff;
  --info-bg-color: #bg-themewolpi-50;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  @apply gap-4 min-h-[44px]
}

.banner.info {
  @apply text-white bg-gradient-info;
}

.banner.upgrade {
  @apply text-white bg-gradient-upgrade;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}

.banner.warning {
  @apply text-white bg-danger-200;
}

.banner.promo {
  @apply text-themewolpi-50 bg-promo;
}

.text-content {
  @apply text-base m-0 leading-6;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.icon-box {
  @apply flex justify-center items-center min-w-[28px] h-[28px] rounded-[6px] justify-self-end;
  text-shadow: none;
}

.icon-box.info {
  @apply bg-white text-themewolpi-50;
}

.icon-box.warning {
  @apply bg-white text-danger-200;
}

.icon-box.promo {
  @apply text-promo bg-themewolpi-50;
}

.icon-box.upgrade {
  @apply bg-white text-[#A39971];
}

.closebutton {
  @apply bg-none border-none cursor-pointer w-6 h-6 flex items-center justify-center;
}

.closebutton.info {
  @apply text-white;
}

.closebutton.warning {
  @apply text-white;
}

.closebutton.promo {
  @apply text-themewolpi-50;
}

.closebutton.upgrade {
  @apply text-white;
}
</style>
